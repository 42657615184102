import {injectScriptCampaigns} from "../config/campaignConfig";

export default function (campaignId) {
    if (injectScriptCampaigns.has(campaignId)) {
        loadVodamediaScripts()
    }
}

function loadVodamediaScripts() {
    loadEntryPointScript()
    loadTrackTargetScript();
    loadCartBoosterScript();
}

function loadEntryPointScript() {
    const entryPointScriptString = `window.tmt_offer_sid = '6kmnu8';
    (function () {
        var timestamp = +new Date;
        var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true;
        po.src = '//cdn.tmtarget.com/tracking/s/entrypoint_v2.min.js?t=' + timestamp;
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);
    })();`;
    const entryPointScript = document.createElement('script');
    entryPointScript.type = "text/javascript";
    entryPointScript.text = entryPointScriptString;
    document.head.appendChild(entryPointScript)

}

function loadTrackTargetScript() {
    const trackTargetScript = document.createElement('script');
    trackTargetScript.src = "//t.trackmytarget.com/jp2b1z/6kmnu8";
    trackTargetScript.async = true;
    document.head.appendChild(trackTargetScript);
}

function loadCartBoosterScript() {
    const cartBoosterScript = document.createElement('script');
    cartBoosterScript.src = "//s.cartbooster.io/preload";
    cartBoosterScript.async = true;
    cartBoosterScript.type = "text/javascript";
    document.head.appendChild(cartBoosterScript);
}
