export const productionConfig = {
    dcbService: `${process.env.VUE_APP_DCB_SERVICE}`,
    upstreamIntegrationService: `${process.env.VUE_APP_UPSTREAM_INTEGRATION_SERVICE}`,
    vodacomIntegrationService: `${process.env.VUE_APP_VODACOM_INTEGRATION_SERVICE}`,
    authHeader: `${process.env.VUE_APP_AUTHORIZATION_HEADERS}`,
    utilsService: `${process.env.VUE_APP_UTILS_SERVICE}`,
}

export const stagingConfig = {
    dcbService: `${process.env.VUE_APP_DCB_SERVICE}`,
    upstreamIntegrationService: `${process.env.VUE_APP_UPSTREAM_INTEGRATION_SERVICE}`,
    vodacomIntegrationService: `${process.env.VUE_APP_VODACOM_INTEGRATION_SERVICE}`,
    authHeader: `${process.env.VUE_APP_AUTHORIZATION_HEADERS}`,
    utilsService: `${process.env.VUE_APP_UTILS_SERVICE}`,
}

const environment = getEnvironment()

export const environmentConfig = getEnvObject();

function getEnvironment() {
    let base_url = window.location.origin;
    if (base_url.includes('staging')) return 'staging'
    if (base_url.includes('localhost')) return 'development'
    else return 'production'
}

function getEnvObject() {
    if (environment === 'staging') return stagingConfig
    if (environment === 'development') return productionConfig
    if (environment === 'production') return productionConfig
}
