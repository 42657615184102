<template>
  <div class="d-flex flex-column align-items-center">
    <div class="app-container col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3 vue-container">
      <router-view/>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {setBrandConfig} from "./functions/setBrandConfig";
import addCustomVodamediaScripts from "./functions/addCustomVodamediaScripts";
import getCampaignParametersFromUrl from "./functions/getCampaignParametersFromUrl";

export default {
  computed: {
    ...mapState(['brandConfig'])
  },
  methods: {
    setFavicon() {
      const favicon = document.getElementById("favicon");
      favicon.href = require(`./brand/${this.brandConfig.brand}/assets/game-logo.png`)
    },
    setTabName() {
      document.title = this.brandConfig.gameTitle;
    },
  },
  beforeMount() {
    setBrandConfig();
    const {campaignId} = getCampaignParametersFromUrl();
    addCustomVodamediaScripts(campaignId);
    this.setFavicon()
    this.setTabName();
  },
}
</script>

<style>
.vue-container {
  position: relative;
  background: #1c1c1c;
  background-size: cover;
  min-height: 100vh;
  color: #FFFFFF;
}
</style>
