import {createStore} from 'vuex'

export default createStore({
    state: {
        brandConfig: undefined,
        campaignId: null,
        googleClickId: undefined,
        evinaToken: undefined,
        encryptedMsisdn: undefined,
        token: undefined,
        msisdn: undefined,
        serviceId: undefined,
        clickId: undefined,
    },
    mutations: {
        setBrandConfig(state, brandConfig) {
            state.brandConfig = brandConfig
        },
        setCampaignId(state, campaignId) {
            state.campaignId = campaignId
        },
        setGoogleClickId(state, googleClickId) {
            state.googleClickId = googleClickId
        },
        setEvinaToken(state, evinaToken) {
            state.evinaToken = evinaToken
        },
        setToken(state, token) {
            state.token = token
        },
        setMsisdn(state, msisdn) {
            state.msisdn = msisdn
        },
        setServiceId(state, serviceId) {
            state.serviceId = serviceId
        },
        setClickId(state, clickId) {
            state.clickId = clickId
        },
    },
    actions: {},
    modules: {}
})
