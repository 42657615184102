export const brandConfigObjectMap = new Map();

export const Brands = {
    VodacomFantasyFootball: 'vc-za-ff',
    VodacomQuizInc: 'vc-za-qi',
    VodacomStopClock: 'vc-za-stc',
    VodacomUnlockThree: 'vc-za-u3',
    VodacomHeadToHead: 'vc-za-h2h',
    VodacomBeatTheJams: 'vc-za-btj',
};

export const GameToBrandMap = new Map();
GameToBrandMap.set('fantasy-football', Brands.VodacomFantasyFootball);
GameToBrandMap.set('quiz-inc', Brands.VodacomQuizInc);
GameToBrandMap.set('stop-the-clock', Brands.VodacomStopClock);
GameToBrandMap.set('unlock-3', Brands.VodacomUnlockThree);
GameToBrandMap.set('head-2-head', Brands.VodacomHeadToHead);
GameToBrandMap.set('beat-the-jams', Brands.VodacomBeatTheJams);

brandConfigObjectMap.set(Brands.VodacomFantasyFootball, {
    gameTitle: 'Fantasy Football',
    gameTag: 'fantasy-football',
    termsConditionsTitle: 'Fantasy Football',
    gameUrl: 'https://vodacomfantasy.com',
    termsConditionsUrl: 'https://termsandconditions.vodacomfantasy.com',
    multiTierService: false,
    ussdString: '*130*2586#',
    baseRoute: '/upstream/opt-in-confirmation',
    revenuePartnerId: 1,
    prizePool: 'R200k in airtime'
});

brandConfigObjectMap.set(Brands.VodacomQuizInc, {
    gameTitle: 'Quizinc',
    gameTag: 'quiz-inc',
    serviceId: 'vc-fanclash-quizinc-01',
    termsConditionsTitle: 'QuizInc VIP',
    gameUrl: 'https://quizinc.co.za',
    termsConditionsUrl: 'https://termsandconditions.quizinc.co.za/quiz-inc',
    multiTierService: false,
    ussdString: '*117*77#',
    baseRoute: '/dcb/opt-in-confirmation',
    prizePool: 'R3 Million in airtime'
});

brandConfigObjectMap.set(Brands.VodacomStopClock, {
    gameTitle: 'Stop the Clock',
    gameTag: 'stop-the-clock',
    serviceId: 'vc-fanclash-quizinc-03',
    termsConditionsTitle: 'Stop the Clock',
    gameUrl: 'https://stopclock.co.za',
    termsConditionsUrl: 'https://termsandconditions.quizinc.co.za/stop-the-clock',
    multiTierService: false,
    productId: 3,
    ussdString: '*117*76#',
    baseRoute: '/dcb/opt-in-confirmation',
    prizePool: '500 daily airtime'
});

brandConfigObjectMap.set(Brands.VodacomUnlockThree, {
    gameTitle: 'Unlock 3',
    gameTag: 'unlock-3',
    serviceId: 'vc-fanclash-quizinc-05',
    termsConditionsTitle: 'Unlock 3',
    gameUrl: 'https://unlock3.co.za',
    termsConditionsUrl: 'https://termsandconditions.quizinc.co.za/unlock-three',
    multiTierService: false,
    ussdString: '*117*75#',
    baseRoute: '/dcb/opt-in-confirmation',
    prizePool: '500 daily airtime'
});

brandConfigObjectMap.set(Brands.VodacomHeadToHead, {
    gameTitle: 'QuizInc Head 2 Head',
    gameTag: 'head-2-head',
    serviceId: 'vc-fanclash-quizinc-04',
    termsConditionsTitle: 'Quizinc Head 2 Head',
    gameUrl: 'https://quizhead2head.co.za',
    termsConditionsUrl: 'https://termsandconditions.quizinc.co.za/head-to-head',
    multiTierService: true,
    freeServiceId: 'vc-fanclash-quizinc-04-free',
    productId: 21,
    ussdString: '*117*74#',
    baseRoute: '/dcb/opt-in-confirmation',
    prizePool: '1,800Gb in data'
});

brandConfigObjectMap.set(Brands.VodacomBeatTheJams, {
    gameTitle: 'Beat the Jams',
    gameTag: 'beat-the-jams',
    serviceId: 'vc-fanclash-beatthejams-01',
    termsConditionsTitle: 'Beat the Jams VIP',
    gameUrl: 'https://beatthejams.co.za',
    termsConditionsUrl: 'https://www.beatthejams.co.za/terms-and-conditions',
    multiTierService: true,
    freeServiceId: 'vc-fanclash-musictrivia-00',
    productId: 18,
    ussdString: '*117*0#',
    baseRoute: '/dcb/opt-in-confirmation',
    prizePool: 'R1,2 Million'
});


