import {brandConfigObjectMap,} from "./brandConfig";

export const brandConfigMap = new Map();

brandConfigMap.set('http://localhost:8080', getBrandConfig('vc-za-ff'));
brandConfigMap.set('http://192.168.88.91:8080', getBrandConfig('vc-za-u3'));
brandConfigMap.set('https://web.quizinc.co.za', getBrandConfig('vc-za-qi'));
brandConfigMap.set('https://web.quizinc.co.za', getBrandConfig('vc-za-qi'));
brandConfigMap.set('https://web.unlock3.co.za', getBrandConfig('vc-za-u3'));
brandConfigMap.set('https://web.stopclock.co.za', getBrandConfig('vc-za-stc'));
brandConfigMap.set('https://web.quizhead2head.co.za', getBrandConfig('vc-za-h2h'));
brandConfigMap.set('https://web.vodacomfantasy.com', getBrandConfig('vc-za-ff'));
brandConfigMap.set('https://web.beatthejams.co.za', getBrandConfig('vc-za-btj'));
brandConfigMap.set('https://web.fantasyfootball.co.za', getBrandConfig('vc-za-ff'));

export function getBrandConfig(brand) {
    const brandConfigObject = brandConfigObjectMap.get(brand);
    return {
        brand,
        gameTitle: brandConfigObject.gameTitle,
        gameTag: brandConfigObject.gameTag,
        serviceId: brandConfigObject.serviceId,
        termsConditionsTitle: brandConfigObject.termsConditionsTitle,
        gameUrl: brandConfigObject.gameUrl,
        termsConditionsUrl: brandConfigObject.termsConditionsUrl,
        multiTierService: brandConfigObject.multiTierService,
        productId: brandConfigObject.productId,
        freeServiceId: brandConfigObject.freeServiceId,
        ussdString: brandConfigObject.ussdString,
        baseRoute: brandConfigObject.baseRoute,
        revenuePartnerId: brandConfigObject.revenuePartnerId,
        prizePool: brandConfigObject.prizePool,
    }
}
