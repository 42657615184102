import { createRouter, createWebHistory } from 'vue-router'
import {userAuthenticate} from "@/functions/userAuthenticate";
import {brandConfigMap} from "@/brand";
import {getBrandConfigFromGameParameter} from "@/functions/setBrandConfig";

const routes = [
  {
    path: '/',
    redirect: getBaseRoute(),
  },
  {
    path: '/dcb/opt-in-confirmation',
    name: 'DoiTwoDcb',
    component: () => import("../views/pages/dcbEvina/DoiTwo"),
  },
  {
    path: '/upstream/opt-in-confirmation',
    name: 'DoiTwoUpstream',
    component: () => import("../views/pages/upstreamEvina/DoiTwo"),
  },
  {
    path: '/wifi',
    name: 'OnWifi',
    component: () => import("../views/pages/errors/OnWifi"),
  },
  {
    path: '/generic-error',
    name: 'GenericError',
    component: () => import("../views/pages/errors/GenericError"),
  },
  {
    path: '/timeout-error',
    name: 'TimeoutError',
    component: () => import("../views/pages/errors/TimeoutError"),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
})

router.beforeEach(async (to, from, next) => {
  await userAuthenticate(to, from, next);
})

function getBaseRoute(){
  const domain = window.origin;
  const brandConfig = brandConfigMap.get(domain) ? brandConfigMap.get(domain) : getBrandConfigFromGameParameter();
  return brandConfig.baseRoute;
}

export default router
