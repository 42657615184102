import store from "../store";
import {Brands} from "../brand/brandConfig";
import DcbService from "../services/dcbService";
import UpstreamIntegrationService from "../services/upstreamIntegrationService";
import UtilsService from "../services/utilsService";
import getCampaignParametersFromUrl from "./getCampaignParametersFromUrl";
import {handleActionClick} from "./handleActionClick";

export async function userAuthenticate(to, from, next) {
    const {brand} = store.state.brandConfig;
    const {serviceId} = store.state.brandConfig;

    switch (brand) {
        case Brands.VodacomUnlockThree:
        case Brands.VodacomStopClock:
        case Brands.VodacomHeadToHead:
        case Brands.VodacomBeatTheJams:
        case Brands.VodacomQuizInc:
            await userAuthenticateInternalEvina(to, from, next, serviceId);
            break;
        case Brands.VodacomFantasyFootball:
            await userAuthenticateFantasyFootball(to, from, next);
            break;
    }
}

async function userAuthenticateInternalEvina(to, from, next, serviceId) {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token') ?? localStorage.getItem('token');
    const campaignParameters = getCampaignParametersFromUrl(urlParams);

    if (token) {
        if (campaignParameters.campaignId) setCampaignId(campaignParameters.campaignId);
        if (campaignParameters.clickId) setClickId(campaignParameters.clickId);
        if (campaignParameters.googleClickId) setGoogleClickId(campaignParameters.googleClickId);
        if (token) return await dcbEncryptedMsisdnAuth(token, next, serviceId);
    } else if (to.name === 'OnWifi' || to.name === 'TimeoutError' || to.name === 'GenericError') {
        return next();
    }
    return next();
}

async function userAuthenticateFantasyFootball(to, from, next) {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token') ?? localStorage.getItem('token');
    const campaignParameters = getCampaignParametersFromUrl(urlParams);

    if (token) {
        if (campaignParameters.campaignId) setCampaignId(campaignParameters.campaignId);
        if (campaignParameters.clickId) setClickId(campaignParameters.clickId);
        if (campaignParameters.googleClickId) setGoogleClickId(campaignParameters.googleClickId);
        if (token) return await upstreamEncryptedMsisdnAuth(token, next);
    } else if (to.name === 'OnWifi' || to.name === 'TimeoutError' || to.name === 'GenericError') {
        return next();
    }
    return next();
}

function setCampaignId(campaignId) {
    store.commit('setCampaignId', campaignId);
}

function setClickId(setClickId) {
    store.commit('setClickId', setClickId);
}

function setGoogleClickId(googleClickId) {
    store.commit('setGoogleClickId', googleClickId);
}

async function dcbEncryptedMsisdnAuth(token, next, serviceId) {
    const msisdn = (await UtilsService.getMsisdnFromEncryptedMsisdn(token)).data.data.msisdn;
    const user = (await DcbService.getUserViaMsisdn(msisdn, undefined, serviceId)).data;
    return await redirectDcbUserBasedOnUserStatusMarketingFlow(user, next, serviceId, msisdn);
}

async function upstreamEncryptedMsisdnAuth(token, next) {
    const msisdn = (await UtilsService.getMsisdnFromEncryptedMsisdn(token)).data.data.msisdn;
    const fantasyToken = (await UpstreamIntegrationService.msisdnLogin(msisdn)).data.token;
    const user = (await UpstreamIntegrationService.getUserStatus(fantasyToken)).data
    return await redirectUpstreamUserBasedOnUserStatusMarketingFlow(user, next);
}

async function redirectDcbUserBasedOnUserStatusMarketingFlow(user, next, serviceId, msisdn) {
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');

    if (user.status === "Active" && !store.state.brandConfig.multiTierService) {
        return await encryptedDcbMsisdnLogin(msisdn);
    }

    if (user.status === "Active" && store.state.brandConfig.multiTierService && user.serviceId === store.state.brandConfig.serviceId) {
        return await encryptedDcbMsisdnLogin(msisdn);
    }

    if (user.status === 'Content Blocked') {
        return next({path: '/generic-error'});
    }

    if (action) {
        return await handleActionClick(user, next, serviceId, msisdn);
    } else {
        window.location = `${store.state.brandConfig.gameUrl}`;
    }
}

async function redirectUpstreamUserBasedOnUserStatusMarketingFlow(user, next) {
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');

    if (user.status === "Subscribed") {
        return await tokenUpstreamLogin(user.token);
    }

    if (user.status === 'Content Blocked') {
        return next({path: '/generic-error'});
    }

    if (action) {
        return await handleActionClick(user, next);
    } else {
        window.location = `${store.state.brandConfig.gameUrl}`;
    }
}

async function encryptedDcbMsisdnLogin(msisdn) {
    const encryptedMsisdn = (await DcbService.getEncryptedMsisdn(msisdn)).data.encryptedMsisdn;
    const {gameUrl, gameTitle} = store.state.brandConfig;

    if (gameTitle === 'Quizinc') {
        window.location = `${gameUrl}/optin/service/4070?cid=100920&bc_id=63&acq-channel=app&optin-channel&?e-msisdn=${encryptedMsisdn}`;
    } else {
        window.location = `${gameUrl}/?e-msisdn=${encryptedMsisdn}`;
    }
}

async function tokenUpstreamLogin(token) {
    window.location = `https://fantasysoccer.vodacomfantasy.com/?fantasyToken=${token}`
}






