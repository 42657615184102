import {environmentConfig} from "../config/enviromentConfig";
import axios from "axios";

const headers = {Authorization: `Basic ${environmentConfig.authHeader}`};

export default class DcbService {
    static async getEncryptedMsisdn(msisdn) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/encrypt-msisdn`, {
                    msisdn
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async getUserViaMsisdn(msisdn, sessionId, serviceId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/get-user-via-msisdn`, {
                    msisdn,
                    serviceId: serviceId,
                    externalRef: 1233564,
                    sessionId
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async doiTwo(msisdn, serviceId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/doi-two`, {
                    msisdn,
                    serviceId: serviceId,
                    origination: "Fanclash - Web"
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async subscribeEncryptedUser(encryptedMsisdn, token, campaignId, evinaToken, contentOverride = undefined, googleClickId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/subscribe/v2`, {
                    encryptedMsisdn: encryptedMsisdn,
                    token: token,
                    serviceId: "vc-fanclash-quizinc-01",
                    "externalRef": 12335642,
                    revenuePartnerId: 33,
                    campaignId: campaignId,
                    acquisitionChannel: 'Organic-Web',
                    evinaToken: evinaToken,
                    contentOverride: contentOverride,
                    googleClickId
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async subscribeUser(msisdn, campaignId, serviceId, googleClickId, clickId) {
        try {
            return await axios.post(`${environmentConfig.dcbService}/subscribe`, {
                    msisdn,
                    serviceId: serviceId,
                    "externalRef": 12335642,
                    revenuePartnerId: 33,
                    campaignId: campaignId ?? 0,
                    acquisitionChannel: 'Organic-Web',
                    googleClickId,
                    clickId
                }, {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async trackUserUpgrade(msisdn, productId, oldService, newService) {
        return await axios.post(`${environmentConfig.dcbService}/track-multi-tier-upgrade`, {
                msisdn,
                productId,
                oldService,
                newService,
            }, {headers}
        )
    }

    static async requestOtp(msisdn, serviceId) {
        return await axios.post(`${environmentConfig.dcbService}/request-otp`, {
                msisdn: msisdn,
                serviceId: serviceId,
            }, {headers}
        )
    }

    static async validateOtp(msisdn, serviceId, otp) {
        return await axios.post(`${environmentConfig.dcbService}/validate-otp`, {
                msisdn: msisdn,
                serviceId: serviceId,
                otp: otp,
            }, {headers}
        )
    }

    static async getMultiTierService(msisdn, productId) {
        return await axios.post(`${environmentConfig.dcbService}/get-multi-tier-service`, {
                msisdn: msisdn,
                productId: productId,
                acquisitionChannel: null,
                campaignId: null
            }, {headers}
        )
    }

    static async trackImpression(campaignId, serviceId) {
        return await axios.post(`${environmentConfig.dcbService}/marketing/track-impression`, {
                msisdn: null,
                campaignId: campaignId,
                acquisitionChannel: 'Organic_Web_Google',
                revenuePartnerId: null,
                onWifi: null,
                serviceId: serviceId
            }, {headers}
        )
    }
}
